import React from 'react';
import {Box, Container, Flex} from 'theme-ui';

import Layout from 'components/layout';
import SEO from 'components/seo';

const DatenschutzPage = () => (
  <Layout>
    <SEO title="Datenschutzerklärung" />
    <Box as="section" id="dataprotection" sx={styles.dataProtection}>
      <Container sx={styles.dataProtection.container}>
        <Flex sx={styles.dataProtection.row}>
          <Box sx={styles.dataProtection.col}>
            <h2>Datenschutzerklärung</h2>
            <h3>1 Identität und die Kontaktdaten des Verantwortlichen</h3>
            <h4>Der Verantwortliche im Sinne des DSG ist die:</h4>
            <p>Confinio GmbH<br />
              Streitgasse 4<br />
              4051 Basel<br />
              Schweiz<br />
              Telefon: +41 556 02 72<br />
              E-Mail: kontakt@confinio.ch<br />
              Website: <a href="https://www.confinio.ch">www.confinio.ch</a><br />
              <br />
              Geschäftsführer/gesetzlicher Vertreter: Dirk Strahberger, Rosemarie Schmidt<br />
            </p>
            <h3>2 Bereitstellung der Website (Logfiles)</h3>
            <h5>2.1 Beschreibung und Umfang der Datenbearbeitung</h5>
            <p>Wenn Sie auf unsere Website zugreifen, d.h., selbst wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse, Referrer-URL, Datum und Uhrzeit des Zugriffs und ähnliches.</p>
            <p>Sie werden insbesondere zu folgenden Zwecken bearbeitet:</p>
            <ol>
              <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
              <li>Sicherstellung einer reibungslosen Nutzung unserer Website, sowie</li>
              <li>Sicherstellung und Auswertung der Systemsicherheit und -stabilität, insbesondere zur Missbrauchserkennung sowie</li>
              <li>zur technisch fehlerfreien Darstellung und Optimierung unserer Website.</li>
            </ol>
            <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen Personendaten des Nutzers findet nicht statt. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Allerdings behalten wir uns vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>

            <h5>2.2 Empfänger der Daten</h5>
            <p>Wir setzen für Betrieb und Wartung unserer Webseite Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h5>2.3 Übermittlung ins Ausland</h5>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>3 Kontaktaufnahme</h3>
            <h5>3.1 Beschreibung und Umfang der Datenbearbeitung</h5>
            <p>Auf unserer Website ist ein Kontaktformular vorhanden, welches für die elektronische Kontaktaufnahme genutzt werden kann. Nehmen Sie diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben Personendaten an uns übermittelt und gespeichert. Die Verarbeitung Ihrer Personendaten dient uns allein zur Bearbeitung Ihrer Anfrage.</p>
            <p>Zum Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten übermittelt und gespeichert:</p>
            <ol>
              <li>Datum und Uhrzeit der Anfrage,</li>
              <li>URL, von der die Anfrage erfolgte,</li>
              <li>IP-Adresse.</li>
            </ol>
            <p>Alternativ ist eine Kontaktaufnahme über die bereitgestellten E-Mail-Adressen möglich. In diesem Fall werden die mit der E-Mail übermittelten Personendaten des Nutzers gespeichert. Hierzu zählen:</p>
            <ol>
              <li>Datum und Uhrzeit der Anfrage,</li>
              <li>URL, von der die Anfrage erfolgte,</li>
              <li>IP-Adresse,</li>
              <li>sowie Informationen zu den an der E-Mail-Kommunikation beteiligten Servern.</li>
            </ol>
            <p>Zudem können Sie über die bereitgestellte Telefonnummer Kontakt zu uns aufnehmen. Hierbei erheben wir Protokolldaten, die Ihre Telefonnummer und die Dauer des Gesprächs beinhalten. Wir zeichnen Gespräche grundsätzlich nicht auf.</p>

            <h5>3.2 Empfänger der Daten</h5>
            <p>Unsere Website wird von Dienstleistern gewartet, die als unsere Auftragsbearbeiter tätig werden. Sofern Sie uns eine Anfrage bzgl. eines Angebots schicken, können von uns eingesetzte Dienstleister zu diesen Zwecken Daten erhalten, sofern diese die Daten zur Erfüllung ihrer jeweiligen Leistung benötigen (z.B. IT-Dienstleistungen, Call-Center). Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h5>3.3 Übermittlung ins Ausland</h5>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>4 Newsletter</h3>
            <h4>4.1 Beschreibung und Umfang der Datenbearbeitung</h4>
            <p>Falls wir Ihnen zukünftig Newsletter zustellen, werden Ihre Daten ausschliesslich dazu verwendet, Ihnen den abonnierten Newsletter per E-Mail zuzustellen und, soweit Sie hierin zusätzlich eingewilligt haben, auszuwerten, wie Sie den Newsletter und ggf. darin verlinkte Inhalte nutzen. Die Angabe Ihres Namens erfolgt, um Sie im Newsletter persönlich ansprechen zu können und ggf. zu identifizieren, falls Sie von Ihren Rechten als Betroffener Gebrauch machen wollen.</p>
            <p>Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir bei einer Online-Anmeldung das „Double-Opt-in“-Verfahren (DOI-Verfahren) ein. Dies bedeutet, dass sie im Anschluss an Ihre Newsletter-Anmeldung eine E-Mail erhalten, in der Sie Ihre Newsletter-Anmeldung durch Klick auf einen Link bestätigen müssen.</p>
            <p>Zum Zeitpunkt der DOI-Bestätigung werden zudem folgende Daten gespeichert:</p>
            <ol>
              <li>Ort, Datum und Uhrzeit der Anmeldung</li>
              <li>IP-Adresse</li>
              <li>E-Mail-Adresse</li>
              <li>Ggfs. Anrede, Vorname, Nachname</li>
            </ol>
            <p>Zusätzlich werten wir Ihr Lese- und Nutzungsverhalten aus, um unseren Newsletter stetig zu verbessern und Ihren Interessen und Bedürfnissen entsprechend anzupassen. Hierzu analysieren wir, ob und was Sie in unseren Newslettern lesen bzw. anklicken, um diese noch attraktiver zu gestalten.</p>

            <h4>4.2 Empfänger der Daten</h4>
            <p>Wir setzen für den Versand und die erfolgenden Auswertungen Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h4>4.3 Übermittlung ins Ausland</h4>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>5 Registrierung</h3>
            <h4>5.1 Beschreibung und Umfang der Datenbearbeitung</h4>
            <p>Falls wir Ihnen auf unserer Website die Möglichkeit bieten, sich unter Angabe Ihrer Personendaten zu registrieren, werden die Daten dabei in eine Eingabemaske eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an Dritte findet nicht statt. Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:</p>
            <ol>
              <li>Vorname, Nachname</li>
              <li>E-Mailadresse</li>
              <li>Passwort</li>
            </ol>
            <p>Zum Zeitpunkt der Registrierung werden zudem folgende Daten gespeichert:</p>
            <ol>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Registrierung</li>
            </ol>
            <p>Im Rahmen des Registrierungsprozesses wird Ihre Einwilligung zur Verarbeitung dieser Daten eingeholt. Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich. Ihre Registrierung ist zur Erfüllung eines Vertrages mit Ihnen oder zur Durchführung vorvertraglicher Massnahmen erforderlich.</p>

            <h4>5.2 Empfänger der Daten</h4>
            <p>Wir setzen für den Registrierungsprozess technische Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h4>5.3 Übermittlung ins Ausland</h4>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>6 Kommentarfunktion</h3>
            <h4>6.1 Beschreibung und Umfang der Datenbearbeitung</h4>
            <p>Falls es zu einigen Inhalten auf unserer Website eine freiwillige Kommentarfunktion gibt, mittels der Sie Ihre Meinung zu einem Beitrag mitteilen können, wird der Kommentar nach positiver Prüfung freigegeben und erscheint öffentlich bei dem Inhalt, wo der Kommentar abgeschickt wurde. Einen Anspruch auf Freigabe eines Kommentars gibt es nicht.</p>
            <p>Wenn Sie Ihren Kommentar auf unserer Website hinterlassen, wird Ihr Name erhoben und öffentlich angezeigt. Zusätzlich wird Ihre E-Mail-Adresse erhoben. Dies dient dazu, Sie bzgl. des Status Ihres Kommentars zu informieren. Die E-Mail-Adresse wird nicht öffentlich angezeigt und nicht an Dritte weitergegeben und nicht manuell ausgewertet. Neben diesen Angaben werden auch der Zeitpunkt der Erstellung und Ihre IP-Adresse gespeichert. Dies dient der Sicherheit des Websitebetreibers, um im Falle von Rechtsverletzungen (zivil- oder strafrechtlicher Natur) gegen den Verfasser vorgehen zu können.</p>
            <p>Die Kommentarfunktion dient dem Austausch von Erfahrungen sowie der Möglichkeit z.B. Nachfragen oder Anregungen mitzuteilen.</p>

            <h4>6.2 Empfänger der Daten</h4>
            <p>Wir setzen für Betrieb und Wartung unserer Webseite Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h4>6.3 Übermittlung ins Ausland</h4>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>7 Verwendung von Cookies</h3>
            <h4>7.1 Beschreibung und Umfang der Datenbearbeitung</h4>
            <p>Ein Cookie ist ein kleiner Datensatz, der beim Besuch einer Website erstellt und auf dem System des Nutzers zwischengespeichert wird. Wird der Server dieser Website erneut von diesem System aufgerufen, sendet der Browser das zuvor empfangene Cookie wieder zurück an den Server. Der Server kann die durch dieses Verfahren erhaltenen Informationen auswerten. Durch Cookies kann insbesondere das Navigieren auf einer Website erleichtert werden.</p>
            <p>Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen.</p>

            <h4>7.2 Verwendung technisch notwendiger Cookies</h4>
            <p>Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
            <p>Wir setzen Cookies zu folgenden Zwecken ein:</p>
            <ul>
              <li>Einwilligungsmanagement</li>
              <li>Spracheinstellungen</li>
              <li>Artikel in einem Warenkorb</li>
              <li>Log-In-Informationen</li>
              <li>Registrierung</li>
            </ul>

            <h4>7.3 Empfänger der Daten</h4>
            <p>Wir setzen für Betrieb und Wartung unserer Webseite technische Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Weitere Empfänger und Einzelheiten über die technische Funktionsweise der eingesetzten Tools und Informationen, wie Sie die Datenübermittlung (Tracking) verhindern können, finden Sie in der unten aufgeführten Tabelle. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h4>7.4 Übermittlung ins Ausland</h4>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>


            <h3>8 Reichweitenmessung</h3>
            <h4>8.1 Beschreibung und Umfang der Datenbearbeitung</h4>
            <p>Mithilfe von Analysewerkzeugen werten wir das Nutzerverhalten aus. Eine genauere Beschreibung zu den Tools finden Sie im Kapitel „Analysewerkzeuge“ dieser Datenschutzerklärung.
              Die Verarbeitung erfolgt auf Basis unseres berechtigten Interesses. Die Messung der Reichweite und die sich daraus ergebenden Informationen sind geeignet, um das Webangebot anzupassen.</p>

            <h4>8.2 Empfänger der Daten</h4>
            <p>Wir setzen für Betrieb und Wartung unserer Webseite technische Dienstleister ein, die als unsere Auftragsbearbeiter tätig werden. Sämtliche Dienstleister sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln.</p>

            <h4>8.3 Übermittlung ins Ausland</h4>
            <p>Eine Bearbeitung findet auch ausserhalb der Schweiz, namentlich in Deutschland, statt. Deutschland wird ein gleichwertiges Datenschutzniveau attestiert. Somit dürfen Personendaten ohne weitere Schutzvorkehrungen dorthin übermittelt werden.</p>

            <h3>9 Eingesetzte Analyse-Tools</h3>
            <p>Wir nutzen den Webanalysedienst „Plausible Analytics“ um unser Angebot fortlaufend zu optimieren, sowohl technisch als auch inhaltlich. Plausible ist eine Marke der Plausible Insights OÜ, Västriku tn 2, 50403, Tartu, Estonia, Registration number 14709274, nachfolgend nur „Plausible“ genannt. Die Plausible Insights OÜ ist vollständig DSGVO-konform.</p>
            <p>Plausible verfolgt einen besonders datenschutzfreundlichen Ansatz zur Analyse Ihres Besuches. Plausible erfasst zu diesem Zweck unter anderem die folgenden Informationen: Datum und Uhrzeit ihres Besuches, Titel und URL der besuchten Seiten, eingehende Links, das Land in dem Sie sich befinden und den User Agent Ihrer Browser-Software. Plausible verwendet und speichert keine „Cookies“ auf Ihrem Endgerät. Sämtliche personenbezogenen Daten (bspw. Ihre IP-Adresse) werden vollständig anonymisiert in Form eines sogenannten Hashes gespeichert. Ein Hash ist eine Verschlüsselung von Daten, die nicht umkehrbar ist, also nicht „entschlüsselt“ werden kann. Auf diese Weise können wir Ihren Besuch analysieren ohne personenbezogenen Daten in einer Form zu speichern, die für uns, Plausible oder fremde Dritte lesbar wäre.</p>
            <p>Weitere Informationen zur technischen Implementierung findest du hier: <a href="https://plausible.io/privacy-focused-web-analytics">https://plausible.io/privacy-focused-web-analytics</a>.</p>
            <p>Weitere Informationen zum Datenschutz bei Plausible findest du unter <a href="https://plausible.io/data-policy">https://plausible.io/data-policy</a>.</p>
            <p>Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. f) DSGVO.</p>

            <h3>10 Rechte der betroffenen Person</h3>
            <p>Sie haben die folgenden Rechte in Bezug auf Personendaten, die Sie betreffen:</p>
            <ul>
              <li>das Recht, Auskunft darüber zu erhalten, welche Personendaten wir von Ihnen speichern und wie wir diese bearbeiten;</li>
              <li>das Recht auf Herausgabe einer Kopie Ihrer Personendaten in einem gängigen Format;</li>
              <li>das Recht auf Berichtigung Ihrer Personendaten;</li>
              <li>das Recht auf Löschung Ihrer Personendaten;</li>
              <li>das Recht, Bearbeitungen Ihrer Personendaten zu widersprechen.</li>
            </ul>
            <p>Beachten Sie bitte, dass für diese Rechte Ausnahmen gelten. Soweit rechtlich zulässig, können wir Ihre Anfrage zur Ausübung dieser Rechte ablehnen.</p>
            <p>Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber widerrufen. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Bearbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.</p>
            <p>Für eine Auskunft über die von uns bearbeiteten Personendaten, senden Sie Ihr Auskunftsbegehren bitte an kontakt@confinio.ch.</p>
            <p>Sie haben ausserdem das Recht, beim Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten (EDÖB) eine Beschwerde einzureichen.</p>

            <p>Diese Datenschutzerklärung wurde mit Hilfe von <a href="https://www.activemind.ch">www.activemind.ch</a> erstellt – den Experten für Datenschutz und Informationssicherheit.</p>


          </Box>
        </Flex>
      </Container>
    </Box>
    <Box sx={styles.layout}>

    </Box>
  </Layout>
);

export default DatenschutzPage;

const styles = {
  dataProtection: {
    overflowX: 'hidden',
    paddingTop: ['100px', '120px'],
    paddingBottom: ['0px', null, '80px'],
    container: {
      maxWidth: ['100%', null, null, null, null, '1240px', '1440px'],
    },
    row: {
      flexWrap: 'wrap',
      display: 'flex',
      marginLeft: '-15px',
      marginRight: '-15px',
    },
    col: {
      pl: '15px',
      pr: '15px',
      flex: ['1 1 100%', null, '0 0 100%'],
    },
  },
};
